function insertElementsByEventsResponse(events) {
  let fragment = document.createDocumentFragment();
  for (const event of events) {
    let template = document.getElementsByClassName("pickup-events-template")[0].cloneNode(true)
    // イベントURL
    let link = template.getElementsByTagName("a")[0]
    link.href = event.url
    // タグ
    let tagGroup = template.getElementsByClassName("tag-group")[0]
    tagGroup.innerHTML = ''
    for (const tag of event.tags) {
      let span = document.createElement("span")
      span.classList.add("tag")
      span.textContent = tag
      tagGroup.appendChild(span)
    }
    // 画像
    let thum = template.getElementsByClassName("thum")[0]
    thum.style = `background-image:url(${event.image_url})`
    let inner = template.getElementsByClassName("inner")[0]
    inner.style = `background-image:url(${event.image_url})`
    // タイトル
    let title = template.getElementsByClassName("title")[0]
    title.textContent = event.title
    // 日付
    let date =  template.getElementsByClassName("date")[0]
    date.textContent = event.begin_date
    // 時間
    let time =  template.getElementsByClassName("time")[0]
    time.textContent = event.open_start
    // ライブハウス名
    let caption =  template.getElementsByClassName("caption")[0]
    caption.textContent = event.space_name

    template.style = "display:block"
    fragment.appendChild(template)
  }
  const container = document.getElementById("pickup-events")
  container.appendChild(fragment)
}

function isPageEnd() {
  return page.current == page.total
}

function pickupEvents(page) {
  if (settings.isLoading) { return }
  settings.isLoading = true
  hideMoreRead()
  showMoreLoading()

  fetch(`${settings.endpoint}/api/events/pickup?page=${page}`)
    .then((response) => {
      // ページ設定更新
      window.page.current = parseInt(response.headers.get("Current-Page"))
      window.page.total = parseInt(response.headers.get("Total-Pages"))
      return response.json()
    })
    .then((data) => {
      insertElementsByEventsResponse(data)
      if (isPageEnd()) {
        document.getElementById("more-read").remove()
      } else {
        showMoreRead()
      }
      hideMoreLoading()
      settings.isLoading = false
    })
}

function showMoreLoading() {
  let loader = document.getElementById("paging-loader")
  loader.style = 'display: block;'
}

function hideMoreLoading() {
  let loader = document.getElementById("paging-loader")
  loader.style = 'display: none;'
}

function showMoreRead() {
  document.getElementById("more-read").style = "display: block;"
}

function hideMoreRead() {
  document.getElementById("more-read").style = "display: none;"
}

document.getElementById("more-read").addEventListener("click", (e) => {
  if (isPageEnd()) { return }
  let next = page.current + 1
  pickupEvents(next)
})

pickupEvents(1)
